import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Flying Dress Photo</h1>
        <p>
          Page coming soon. For information on booking a Flying Dress session,
          please contact Kristin at kristin.kee@gmail.com.
        </p>
      </header>
    </div>
  );
}

export default App;
